import { FeatureFlagProvider } from '@touchbistro/node-common'
import { FeatureFlagProvider as FeatureFlagProviderForClient } from '@touchbistro/react-feature-flags'
import { Policies } from '~/src/shared/helpers/permissions'
import { LD_FLAG_VENUE_GROUPING_SELECTOR } from '~/src/shared/initializers/ld_client'
import { POSWebviewMessage, PoliciesForAccessibleBases } from '~/src/shared/types'

export const isPOSWebview = (incomingUserAgent: string): boolean => {
  return incomingUserAgent.startsWith('POS Webview')
}

export const SendPOSWebviewMessage = (message: POSWebviewMessage): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const win = window as any
  if (win.webkit && win.webkit.messageHandlers && win.webkit.messageHandlers.toggleMessageHandler) {
    win.webkit.messageHandlers.toggleMessageHandler.postMessage({
      message,
    })
  } else if (win.ReactNativeWebView) {
    win.ReactNativeWebView.postMessage(
      JSON.stringify({
        message,
      })
    )
  } else {
    throw new Error('windows webkit/reactnative is not able to post message.')
  }
}

export const getUserRootPolicies = (
  policiesForAccessibleBases: PoliciesForAccessibleBases,
  rootBaseXRefID?: string
): {
  [policy in Policies]?: boolean
} => {
  return rootBaseXRefID
    ? Object.entries(policiesForAccessibleBases).reduce((acc, [policy, bases]) => {
        if (bases.includes(rootBaseXRefID)) {
          return {
            ...acc,
            [policy]: true,
          }
        }
        return acc
      }, {})
    : {}
}

// This checks if base has RMM3 and is part of ark.base-selector-venue-grouping FF.
// Once groups is released on 18-09-2024, the FF will be deprecated soon after, and
// we will rely on the RMM3 entitlement check.
// Once all customers are on RMM3 this check will be deprecated.
export const isVenueGroupingEnabledForClient = ({
  featureFlagContext,
  baseHasRMM3Entitlement,
}: {
  featureFlagContext: { client: FeatureFlagProviderForClient } | undefined
  baseHasRMM3Entitlement: boolean
}): boolean => {
  if (featureFlagContext == null) {
    return false
  }

  // This flag will be turned on for all RMM3 bases
  // once groups work is released on Sep 18, 2024
  if (baseHasRMM3Entitlement) {
    return featureFlagContext.client.variation(LD_FLAG_VENUE_GROUPING_SELECTOR, false)
  }

  // For all RMM2 bases, do not show group behaviour
  return false
}

export const isVenueGroupingEnabledForServer = async ({
  featureFlagProvider,
  baseHasRMM3Entitlement,
  baseXRefID,
}: {
  featureFlagProvider: FeatureFlagProvider
  baseHasRMM3Entitlement: boolean
  baseXRefID: string
}): Promise<boolean> => {
  // This flag will be turned on for all RMM3 bases
  // once groups work is released on Sep 18, 2024
  if (baseHasRMM3Entitlement) {
    return await featureFlagProvider.variation(
      // Can't import FeatureFlag enum from ld_server
      // so hardcoding the FF key here.
      'ark.base-selector-venue-grouping',
      {
        anonymous: false,
        key: `baseXRefID-${baseXRefID}`,
      },
      false
    )
  }

  // For all RMM2 bases, do not show group behaviour
  return false
}
