const staffEN = {
  'stf.loyalty_groups': 'Guest Engagement Groups',
  'stf.select_groups': 'Select Groups',
  'stf.role_information': 'Role Information',
  'stf.permissions.main_title': 'Permissions',
  'stf.permissions.role_name_text': 'Role Name',
  'stf.permissions.role_description_text': 'Role Description',

  // POS
  'stf.permissions.pos': 'POS',
  'stf.permissions.pos.restart': 'Can Start Day (Restarting the Android app)',
  'stf.permissions.pos.launchTouchBistro': 'Can Action Update (within TouchBistro)',
  'stf.permissions.pos.clockInOnly': 'Clock-In / Clock Out Only',
  'stf.permissions.order.void': 'Can Authorize and Perform Voids without Approval',
  'stf.permissions.order.discount': 'Can Authorize and Perform Discounts without Approval',
  'stf.permissions.order.gratuity': 'Can Authorize and Add Gratuity',
  'stf.permissions.cashdrawer.open': 'Can Open Cash Drawer',
  'stf.permissions.order.refund': 'Can Refund Closed Bills',
  'stf.permissions.cashdrawer.payInOut': 'Can Perform Pay-Ins/Pay-Outs',
  'stf.permissions.pos.accessTableIfSameRole': 'Can Access Other Staff Tables without Approval',
  'stf.permissions.pos.accessTakeout': 'Can Access Takeout / Delivery',
  'stf.permissions.pos.enableFastBar': 'Enable Fast Bar On Point of Sale',
  'stf.permissions.pos.requiresCashTip': 'Required Recording of Cash Tips',
  'stf.permissions.pos.accessCashRegister': 'Can Access Cash Registers on Floor Plan',
  'stf.permissions.pos.accessCustomerAccounts': 'Can Access Accounts',
  'stf.permissions.pos.enablePersonalRegister': 'Can Access Personal Register',
  'stf.permissions.pos.managePaymentGateways': 'Can Manage Payment Gateways',
  'stf.permissions.pos.manageOrders': 'Can Manage Orders',
  'stf.permissions.pos.performEndOfDay': 'Can Perform EOD',
  'stf.permissions.pos.admin': 'Can Access Admin Options',
  'stf.permissions.report.endOfShift': 'Show Current and Past Shifts',
  'stf.permissions.autoProcessOrder.read': 'Can View an Auto Processed Order',
  'stf.permissions.autoProcessOrder.update': 'Can Update an Auto Processed Order',
  'stf.permissions.autoProcessOrder.delete': 'Can Delete an Auto Processed Order',
  'stf.permissions.order.take': 'Can Take Orders (Android only)',
  'stf.permissions.partners.overrideShift': 'Allow Shift Overrides with Partner Integrations',

  // Cash Management
  'stf.permissions.report.manageDepositsBlind': 'Can Create / Edit Blind Cash Deposits',
  'stf.permissions.report.manageDepositsFull': 'Can Create / Edit Full Cash Deposits',

  // Staff
  'stf.permissions.staff.section_title': 'Staff',
  'stf.permissions.staff': 'Can Manage Staff',
  'stf.permissions.staff.create': 'Add a User',
  'stf.permissions.staff.update': 'Edit a User',
  'stf.permissions.staff.delete': 'Delete a User',
  'stf.permissions.staff.read': 'View Roles',

  'stf.permissions.roles': 'Can Manage Roles',
  'stf.permissions.roles.create': 'Add a Role',
  'stf.permissions.roles.update': 'Edit a Role',
  'stf.permissions.roles.delete': 'Delete a Role',

  'stf.permissions.shifts.manage': 'Can Manage Shifts',

  'stf.permissions.overtimeRules.manage': 'Can Manage Overtime Rules',

  // Devices
  'stf.permissions.device.section_title': 'Devices',

  'stf.permissions.device': 'Can Manage Devices',
  'stf.permissions.device.read': 'Access a Device',
  'stf.permissions.device.update': 'Edit a Device',

  'stf.permissions.printer': 'Can Manage Printers',
  'stf.permissions.printer.read': 'Access a Printer',
  'stf.permissions.printer.create': 'Add a Printer',
  'stf.permissions.printer.update': 'Edit a Printer',
  'stf.permissions.printer.delete': 'Remove a Printer',

  'stf.permissions.pos.manage': 'POS Manage - Other Devices',

  // Menu
  'stf.permissions.menu.section_title': 'Menu',

  'stf.permissions.operationalMenu.publish': 'Menu Publishing',
  'stf.permissions.operationalMenu.readHistory': 'Menu Read History',

  'stf.permissions.menu': 'Can Manage Menu Settings',
  'stf.permissions.menu.read': 'View a Menu',
  'stf.permissions.menu.create': 'Create a Menu',
  'stf.permissions.menu.update': 'Update a Menu',
  'stf.permissions.menu.delete': 'Delete a Menu',

  'stf.permissions.menuEntityHistory.read': 'Menu Read Entity History',

  'stf.permissions.menuGroup': 'Can Manage Menu Groups',
  'stf.permissions.menuGroup.read': 'View a Menu Group',
  'stf.permissions.menuGroup.create': 'Create a Menu Group',
  'stf.permissions.menuGroup.update': 'Update a Menu Group',
  'stf.permissions.menuGroup.delete': 'Delete a Menu Group',

  'stf.permissions.menuItem': 'Can Manage Menu Items',
  'stf.permissions.menuItem.read': 'View a Menu Item',
  'stf.permissions.menuItem.create': 'Create a Menu Item',
  'stf.permissions.menuItem.update': 'Update a Menu Item',
  'stf.permissions.menuItem.delete': 'Delete a Menu Item',

  'stf.permissions.modifierGroup': 'Can Manage Modifier Groups',
  'stf.permissions.modifierGroup.read': 'View a Modifier Group',
  'stf.permissions.modifierGroup.create': 'Create a Modifier Group',
  'stf.permissions.modifierGroup.update': 'Update a Modifier Group',
  'stf.permissions.modifierGroup.delete': 'Delete a Modifier Group',

  'stf.permissions.modifier': 'Can Manage Modifiers',
  'stf.permissions.modifier.read': 'View a Modifiers',
  'stf.permissions.modifier.create': 'Create a Modifiers',
  'stf.permissions.modifier.update': 'Update a Modifiers',
  'stf.permissions.modifier.delete': 'Delete a Modifiers',

  'stf.permissions.salesCategory': 'Can Manage Sales Categories',
  'stf.permissions.salesCategory.read': 'View a Sales Category',
  'stf.permissions.salesCategory.create': 'Create a Sales Category',
  'stf.permissions.salesCategory.update': 'Update a Sales Category',
  'stf.permissions.salesCategory.delete': 'Delete a Sales Category',

  'stf.permissions.bulkMenu': 'Can Manage Bulk Menu Editing',
  'stf.permissions.bulkMenu.create': 'Create a Bulk Menu',
  'stf.permissions.bulkMenu.delete': 'Delete a Bulk Menu',

  'stf.permissions.image': 'Can Manage Menu Images',
  'stf.permissions.image.create': 'Create a menu image',
  'stf.permissions.image.read': 'View a menu image',
  'stf.permissions.image.delete': 'Delete a menu image',

  'stf.permissions.scheduledPublication': 'Can Manage Scheduling Publications',
  'stf.permissions.scheduledPublication.update': 'Update a Scheduling Publication',
  'stf.permissions.scheduledPublication.read': 'View a Scheduling Publication',
  'stf.permissions.scheduledPublication.delete': 'Delete a Scheduling Publication',

  'stf.permissions.promotion': 'Can Manage Promotional Pricing',
  'stf.permissions.promotion.create': 'Create a Promotional Pricing',
  'stf.permissions.promotion.update': 'Update a Promotional Pricing',
  'stf.permissions.promotion.read': 'View a Promotional Pricing',
  'stf.permissions.promotion.delete': 'Delete a Promotional Pricing',

  'stf.permissions.voidReason': 'Can Manage Voids',
  'stf.permissions.voidReason.read': 'Access a Void',
  'stf.permissions.voidReason.create': 'Create a Void',
  'stf.permissions.voidReason.update': 'Update a Void',
  'stf.permissions.voidReason.delete': 'Remove a Void',

  'stf.permissions.discount': 'Can Manage Discounts',
  'stf.permissions.discount.read': 'Access a Discount',
  'stf.permissions.discount.create': 'Create a Discount',
  'stf.permissions.discount.update': 'Update a Discount',
  'stf.permissions.discount.delete': 'Remove a Discount',

  'stf.permissions.menuEntryHistory.read': 'Can View Menu Entry History',

  // Online Ordering

  // Reports
  'stf.permissions.reports.section_title': 'Reports',
  'stf.permissions.reports.cash_deposit': 'Cash Deposit',
  'stf.permissions.reports': 'Can Access Reports',
  'stf.permissions.report.read': 'Can Access Reports',
  'stf.permissions.eodreport.read': 'Can Access End of Day Report',

  // Floor Plan
  'stf.permissions.floorplan.section_title': 'Floor Plan',

  'stf.permissions.floorplan': 'Can Manage Floor Plan',
  'stf.permissions.floorplan.read': 'Read Floor Plan',
  'stf.permissions.floorplan.create': 'Create a Floor Plan',
  'stf.permissions.floorplan.update': 'Update a Floor Plan',
  'stf.permissions.floorplan.delete': 'Delete a Floor Plan',

  // Guest Engagement
  'stf.permissions.guest_engagement.section_title': 'Guest Engagement',

  'stf.permissions.guestEngagement': 'Can Manage Rewards',
  'stf.permissions.guestEngagement.read': 'Access a Reward',
  'stf.permissions.guestEngagement.create': 'Create a Reward',
  'stf.permissions.guestEngagement.update': 'Update a Reward',
  'stf.permissions.guestEngagement.delete': 'Delete a Reward',

  // Reservations
  'stf.permissions.reservations.section_title': 'Reservations',
  'stf.permissions.reservations.allSettings': 'Can Manage All Reservation Settings',
  'stf.permissions.reservations.manageFOH': 'Can Manage FOH Reservation Activity',

  'stf.permissions.reservationSetting': 'Can Manage Reservations',
  'stf.permissions.reservationSetting.create': 'Create a Reservation',
  'stf.permissions.reservationSetting.read': 'Access a Reservation',
  'stf.permissions.reservationSetting.update': 'Update a Reservation',
  'stf.permissions.reservationSetting.delete': 'Remove a Reservation',

  // Settings
  'stf.permissions.settings.section_title': 'Manage Settings & Venue Info',

  'stf.permissions.venue.update': 'Can Manage Venue Details',
  'stf.permissions.businessday.manage': 'Can manage Business Day',

  'stf.permissions.deliveryBoundsAndServiceCharge': 'Can Manage Online Ordering Settings',
  'stf.permissions.deliveryBounds.create': 'Create a delivery boundary',
  'stf.permissions.deliveryBounds.read': 'Read a delivery boundary',
  'stf.permissions.deliveryBounds.update': 'Update a delivery boundary',
  'stf.permissions.deliveryBounds.delete': 'Delete a delivery boundary',
  'stf.permissions.serviceCharge.create': 'Create a service charge',
  'stf.permissions.serviceCharge.read': 'Read a service charge',
  'stf.permissions.serviceCharge.update': 'Update a service charge',
  'stf.permissions.serviceCharge.delete': 'Delete a service charge',

  'stf.permissions.tax': 'Can Manage Taxes',
  'stf.permissions.tax.read': 'Access a Tax',
  'stf.permissions.tax.create': 'Create a Tax',
  'stf.permissions.tax.update': 'Update a Tax',
  'stf.permissions.tax.delete': 'Delete a Tax',

  'stf.permissions.branding': 'Can Manage Branding',
  'stf.permissions.branding.create': 'Create a Brand',
  'stf.permissions.branding.read': 'Access a Brand',
  'stf.permissions.branding.update': 'Update a Brand',
  'stf.permissions.branding.delete': 'Remove a Brand',

  'stf.permissions.hoursAndScheduling.update': 'Can Manage Hours & Scheduling',
  'stf.permissions.hoursAndScheduling.read': 'Can Read Hours & Scheduling',
  'stf.permissions.venueInfoDayParts.update': 'Can Manage Day Parts',
  'stf.permissions.venueInfoDayParts.read': 'Can Read Day Parts',
  'stf.permissions.venueInfoOperatingHours.update': 'Can Manage Operating Hours',
  'stf.permissions.venueInfoOperatingHours.read': 'Can Read Operating Hours',
  'stf.permissions.venueInfoServiceTime.update': 'Can Manage Service Time',
  'stf.permissions.venueInfoServiceTime.read': 'Can Read Service Time',
  'stf.permissions.paymentSettings.manage': 'Can Manage Payment Settings',

  // Payments Portal
  'stf.permissions.payments_portal.section_title': 'Payments Portal',

  'stf.permissions.payments': 'Access to CS Portal (TB Payments)',
  'stf.permissions.payments.create': 'Create Payments',
  'stf.permissions.payments.delete': 'Remove Payments',
  'stf.permissions.payments.read': 'Access Payments',
  'stf.permissions.payments.update': 'Update Payments',

  // App Marketplace
  'stf.permissions.marketplace.section_title': 'App Marketplace',
  'stf.permissions.marketplace.manage': 'Can Manage App Marketplace',

  // Service Fees
  'stf.permissions.pos.authorizeAndPerformServiceFeesWithoutApproval':
    'Can Authorize and Perform Service Fees without Approval',
}

export default staffEN
