const staffES = {
  'stf.loyalty_groups': 'Grupos de Guest Engagement',
  'stf.select_groups': 'Seleccionar grupos',
  'stf.role_information': '[ES] Role Information',
  'stf.permissions.main_title': '[ES] Permissions',
  'stf.permissions.role_name_text': '[ES] Role Name',
  'stf.permissions.role_description_text': '[ES] Role Description',

  // POS
  'stf.permissions.pos': '[ES] POS',
  'stf.permissions.pos.restart': '[ES] Can Start Day (Restarting the Android app)',
  'stf.permissions.pos.launchTouchBistro': '[ES] Can Action Update (within TouchBistro)',
  'stf.permissions.pos.clockInOnly': '[ES] Clock-In / Clock Out Only',
  'stf.permissions.order.void': '[ES] Can Authorize and Perform Voids without Approval',
  'stf.permissions.order.discount': '[ES] Can Authorize and Perform Discounts without Approval',
  'stf.permissions.order.gratuity': '[ES] Can Authorize and Add Gratuity',
  'stf.permissions.cashdrawer.open': '[ES] Can Open Cash Drawer',
  'stf.permissions.order.refund': '[ES] Can Refund Closed Bills',
  'stf.permissions.cashdrawer.payInOut': '[ES] Can Perform Pay-Ins/Pay-Outs',
  'stf.permissions.pos.accessTableIfSameRole':
    '[ES] Can Access Other Staff Tables without Approval',
  'stf.permissions.pos.accessTakeout': '[ES] Can Access Takeout / Delivery',
  'stf.permissions.pos.enableFastBar': '[ES] Enable Fast Bar On Point of Sale',
  'stf.permissions.pos.requiresCashTip': '[ES] Required Recording of Cash Tips',
  'stf.permissions.pos.accessCashRegister': '[ES] Can Access Cash Registers on Floor Plan',
  'stf.permissions.pos.accessCustomerAccounts': '[ES] Can Access Accounts',
  'stf.permissions.pos.enablePersonalRegister': '[ES] Can Access Personal Register',
  'stf.permissions.pos.managePaymentGateways': '[ES] Can Manage Payment Gateways',
  'stf.permissions.pos.manageOrders': '[ES] Can Manage Orders',
  'stf.permissions.pos.performEndOfDay': '[ES] Can Perform EOD',
  'stf.permissions.pos.admin': '[ES] Can Access Admin Options',
  'stf.permissions.report.endOfShift': '[ES] Show Current and Past Shifts',
  'stf.permissions.autoProcessOrder.read': '[ES] Can View an Auto Processed Order',
  'stf.permissions.autoProcessOrder.update': '[ES] Can Update an Auto Processed Order',
  'stf.permissions.autoProcessOrder.delete': '[ES] Can Delete an Auto Processed Order',
  'stf.permissions.order.take': '[ES] Can Take Orders (Android only)',
  'stf.permissions.partners.overrideShift':
    'Permitir anulaciones de turnos con integraciones de socios',

  // Cash Management
  'stf.permissions.report.manageDepositsBlind': '[ES] Can Create / Edit Blind Cash Deposits',
  'stf.permissions.report.manageDepositsFull': '[ES] Can Create / Edit Full Cash Deposits',

  // Staff
  'stf.permissions.staff.section_title': '[ES] Staff',
  'stf.permissions.staff': '[ES] Can Manage Staff',
  'stf.permissions.staff.create': '[ES] Add a User',
  'stf.permissions.staff.update': '[ES] Edit a User',
  'stf.permissions.staff.delete': '[ES] Delete a User',
  'stf.permissions.staff.read': '[ES] View Roles',

  'stf.permissions.roles': '[ES] Can Manage Roles',
  'stf.permissions.roles.create': '[ES] Add a Role',
  'stf.permissions.roles.update': '[ES] Edit a Role',
  'stf.permissions.roles.delete': '[ES] Delete a Role',

  'stf.permissions.shifts.manage': '[ES] Can Manage Shifts',

  'stf.permissions.overtimeRules.manage': '[ES] Can Manage Overtime Rules',

  // Devices
  'stf.permissions.device.section_title': '[ES] Devices',

  'stf.permissions.device': '[ES] Can Manage Devices',
  'stf.permissions.device.read': '[ES] Access a Device',
  'stf.permissions.device.update': '[ES] Edit a Device',

  'stf.permissions.printer': '[ES] Can Manage Printers',
  'stf.permissions.printer.read': '[ES] Access a Printer',
  'stf.permissions.printer.create': '[ES] Add a Printer',
  'stf.permissions.printer.update': '[ES] Edit a Printer',
  'stf.permissions.printer.delete': '[ES] Remove a Printer',

  'stf.permissions.pos.manage': '[ES] POS Manage - Other Devices',

  // Menu
  'stf.permissions.menu.section_title': '[ES] Menu',

  'stf.permissions.operationalMenu.publish': '[ES] Menu Publishing',
  'stf.permissions.operationalMenu.readHistory': '[ES] Menu Read History',

  'stf.permissions.menu': '[ES] Can Manage Menus',
  'stf.permissions.menu.read': '[ES] View a Menu',
  'stf.permissions.menu.create': '[ES] Create a Menu',
  'stf.permissions.menu.update': '[ES] Update a Menu',
  'stf.permissions.menu.delete': '[ES] Delete a Menu',

  'stf.permissions.menuEntityHistory.read': '[ES] Menu Read Entity History',

  'stf.permissions.menuGroup': '[ES] Can Manage Menu Groups',
  'stf.permissions.menuGroup.read': '[ES] View a Menu Group',
  'stf.permissions.menuGroup.create': '[ES] Create a Menu Group',
  'stf.permissions.menuGroup.update': '[ES] Update a Menu Group',
  'stf.permissions.menuGroup.delete': '[ES] Delete a Menu Group',

  'stf.permissions.menuItem': '[ES] Can Manage Menu Items',
  'stf.permissions.menuItem.read': '[ES] View a Menu Item',
  'stf.permissions.menuItem.create': '[ES] Create a Menu Item',
  'stf.permissions.menuItem.update': '[ES] Update a Menu Item',
  'stf.permissions.menuItem.delete': '[ES] Delete a Menu Item',

  'stf.permissions.modifierGroup': '[ES] Can Manage Modifier Groups',
  'stf.permissions.modifierGroup.read': '[ES] View a Modifier Group',
  'stf.permissions.modifierGroup.create': '[ES] Create a Modifier Group',
  'stf.permissions.modifierGroup.update': '[ES] Update a Modifier Group',
  'stf.permissions.modifierGroup.delete': '[ES] Delete a Modifier Group',

  'stf.permissions.modifier': '[ES] Can Manage Modifiers',
  'stf.permissions.modifier.read': '[ES] View a Modifiers',
  'stf.permissions.modifier.create': '[ES] Create a Modifiers',
  'stf.permissions.modifier.update': '[ES] Update a Modifiers',
  'stf.permissions.modifier.delete': '[ES] Delete a Modifiers',

  'stf.permissions.salesCategory': '[ES] Can Manage Sales Categories',
  'stf.permissions.salesCategory.read': '[ES] View a Sales Category',
  'stf.permissions.salesCategory.create': '[ES] Create a Sales Category',
  'stf.permissions.salesCategory.update': '[ES] Update a Sales Category',
  'stf.permissions.salesCategory.delete': '[ES] Delete a Sales Category',

  'stf.permissions.bulkMenu': '[ES] Can Manage Bulk Menu Editing',
  'stf.permissions.bulkMenu.create': '[ES] Create a Bulk Menu',
  'stf.permissions.bulkMenu.delete': '[ES] Delete a Bulk Menu',

  'stf.permissions.image': '[ES] Can Manage Menu Images',
  'stf.permissions.image.create': '[ES] Create a menu image',
  'stf.permissions.image.read': '[ES] View a menu image',
  'stf.permissions.image.delete': '[ES] Delete a menu image',

  'stf.permissions.scheduledPublication': '[ES] Can Manage Scheduling Publications',
  'stf.permissions.scheduledPublication.update': '[ES] Update a Scheduling Publication',
  'stf.permissions.scheduledPublication.read': '[ES] View a Scheduling Publication',
  'stf.permissions.scheduledPublication.delete': '[ES] Delete a Scheduling Publication',

  'stf.permissions.promotion': '[ES] Can Manage Promotional Pricing',
  'stf.permissions.promotion.create': '[ES] Create a Promotional Pricing',
  'stf.permissions.promotion.update': '[ES] Update a Promotional Pricing',
  'stf.permissions.promotion.read': '[ES] View a Promotional Pricing',
  'stf.permissions.promotion.delete': '[ES] Delete a Promotional Pricing',

  'stf.permissions.voidReason': '[ES] Can Manage Voids',
  'stf.permissions.voidReason.read': '[ES] Access a Void',
  'stf.permissions.voidReason.create': '[ES] Create a Void',
  'stf.permissions.voidReason.update': '[ES] Update a Void',
  'stf.permissions.voidReason.delete': '[ES] Remove a Void',

  'stf.permissions.discount': '[ES] Can Manage Discounts',
  'stf.permissions.discount.read': '[ES] Access a Discount',
  'stf.permissions.discount.create': '[ES] Create a Discount',
  'stf.permissions.discount.update': '[ES] Update a Discount',
  'stf.permissions.discount.delete': '[ES] Remove a Discount',

  'stf.permissions.menuEntryHistory.read': '[ES] Can View Menu Entry History',

  // Online Ordering

  // Reports
  'stf.permissions.reports.section_title': '[ES] Reports',
  'stf.permissions.reports.cash_deposit': '[ES] Cash Deposit',
  'stf.permissions.reports': 'Can Access Reports',
  'stf.permissions.report.read': '[ES] Can Access Reports',
  'stf.permissions.eodreport.read': '[ES] Can Access End of Day Report',

  // Floor Plan
  'stf.permissions.floorplan.section_title': '[ES] Floor Plan',

  'stf.permissions.floorplan': '[ES] Can Manage Floor Plan',
  'stf.permissions.floorplan.read': '[ES] Read Floor Plan',
  'stf.permissions.floorplan.create': '[ES] Create a Floor Plan',
  'stf.permissions.floorplan.update': '[ES] Update a Floor Plan',
  'stf.permissions.floorplan.delete': '[ES] Delete a Floor Plan',

  // Guest Engagement
  'stf.permissions.guest_engagement.section_title': '[ES] Guest Engagement',

  'stf.permissions.guestEngagement': '[ES] Can Manage Rewards',
  'stf.permissions.guestEngagement.read': '[ES] Access a Reward',
  'stf.permissions.guestEngagement.create': '[ES] Create a Reward',
  'stf.permissions.guestEngagement.update': '[ES] Update a Reward',
  'stf.permissions.guestEngagement.delete': '[ES] Delete a Reward',

  // Reservations
  'stf.permissions.reservations.section_title': '[ES] Reservations',
  'stf.permissions.reservations.allSettings': '[ES] Can Manage All Reservation Settings',
  'stf.permissions.reservations.manageFOH': '[ES] Can Manage FOH Reservation Activity',

  'stf.permissions.reservationSetting': '[ES] Can Manage Reservations',
  'stf.permissions.reservationSetting.create': '[ES] Create a Reservation',
  'stf.permissions.reservationSetting.read': '[ES] Access a Reservation',
  'stf.permissions.reservationSetting.update': '[ES] Update a Reservation',
  'stf.permissions.reservationSetting.delete': '[ES] Remove a Reservation',

  // Settings
  'stf.permissions.settings.section_title': '[ES] Manage Settings & Venue Info',

  'stf.permissions.venue.update': '[ES] Can Manage Venue Details',
  'stf.permissions.businessday.manage': '[ES] Can manage Business Day',

  'stf.permissions.deliveryBoundsAndServiceCharge': '[ES] Can Manage Online Ordering Settings',
  'stf.permissions.deliveryBounds.create': '[ES] Create a delivery boundary',
  'stf.permissions.deliveryBounds.read': '[ES] Read a delivery boundary',
  'stf.permissions.deliveryBounds.update': '[ES] Update a delivery boundary',
  'stf.permissions.deliveryBounds.delete': '[ES] Delete a delivery boundary',
  'stf.permissions.serviceCharge.create': '[ES] Create a service charge',
  'stf.permissions.serviceCharge.read': '[ES] Read a service charge',
  'stf.permissions.serviceCharge.update': '[ES] Update a service charge',
  'stf.permissions.serviceCharge.delete': '[ES] Delete a service charge',

  'stf.permissions.tax': '[ES] Can Manage Taxes',
  'stf.permissions.tax.read': '[ES] Access a Tax',
  'stf.permissions.tax.create': '[ES] Create a Tax',
  'stf.permissions.tax.update': '[ES] Update a Tax',
  'stf.permissions.tax.delete': '[ES] Delete a Tax',

  'stf.permissions.branding': '[ES] Can Manage Branding',
  'stf.permissions.branding.create': '[ES] Create a Brand',
  'stf.permissions.branding.read': '[ES] Access a Brand',
  'stf.permissions.branding.update': '[ES] Update a Brand',
  'stf.permissions.branding.delete': '[ES] Remove a Brand',

  'stf.permissions.hoursAndScheduling.update': 'Puede administrar horas y programación',
  'stf.permissions.hoursAndScheduling.read': '[ES] Can Read Hours & Scheduling',
  'stf.permissions.venueInfoDayParts.update': '[ES] Can Manage Day Parts',
  'stf.permissions.venueInfoDayParts.read': '[ES] Can Read Day Parts',
  'stf.permissions.venueInfoOperatingHours.update': '[ES] Can Manage Operating Hours',
  'stf.permissions.venueInfoOperatingHours.read': '[ES] Can Read Operating Hours',
  'stf.permissions.venueInfoServiceTime.update': '[ES] Can Manage Service Time',
  'stf.permissions.venueInfoServiceTime.read': '[ES] Can Read Service Time',
  'stf.permissions.paymentSettings.manage': '[ES] Can Manage Payment Settings',

  // Payments Portal
  'stf.permissions.payments_portal.section_title': '[ES] Payments Portal',

  'stf.permissions.payments': '[ES] Access to CS Portal (TB Payments)',
  'stf.permissions.payments.create': '[ES] Create Payments',
  'stf.permissions.payments.delete': '[ES] Remove Payments',
  'stf.permissions.payments.read': '[ES] Access Payments',
  'stf.permissions.payments.update': '[ES] Update Payments',

  // App Marketplace
  'stf.permissions.marketplace.section_title': '[ES] App Marketplace',
  'stf.permissions.marketplace.manage': '[ES] Can Manage App Marketplace',

  // Service Fees
  'stf.permissions.pos.authorizeAndPerformServiceFeesWithoutApproval':
    '[ES] Can Authorize and Perform Service Fees without Approval',
}

export default staffES
